import { MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN } from "@/config/constants";
import type { Product } from "@/types/product";
import { client } from "./client/client";
import type { Paginated } from "@/types/pagination";
import { parseCursor } from "./utils";

export async function findProducts(
  organizationId: number,
  search: string | null,
  limit: number = MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN
) {
  const response = await client.get<Product[]>(
    `/organizations/${organizationId}/products/search/`,
    { params: { search, page_size: limit } }
  );
  return response.data.slice(0, limit);
}

export async function listProducts(
  organizationId: number,
  search: string | null,
  cursor: string | null,
  pageSize: number
) {
  const response = await client.get<Paginated<Product>>(
    `/organizations/${organizationId}/products/`,
    { params: { search, cursor, page_size: pageSize, page_result_count: true } }
  );
  return {
    ...response.data,
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
  };
}
