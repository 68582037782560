<template>
  <q-menu context-menu>
    <q-list dense>
      <q-item
        v-if="organization?.useSupplierData"
        clickable
        v-close-popup
        @click="updateShowSupplierRfqs(true)"
        :disable="!showAddSupplierRfq || disabled"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.addSupplierRfq")
        }}</q-item-section>
      </q-item>
      <q-item
        v-if="organization?.useSupplierData"
        clickable
        v-close-popup
        @click="updateShowSupplierRfqs(false)"
        :disable="!showCancelSupplierRfq || disabled"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.removeSupplierRfq")
        }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps<{
  disabled: boolean;
  selectedGroupIds: number[];
}>();

const emit = defineEmits<{
  "update-show-supplier-rfqs": [showSupplierRfqs: boolean];
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const opgStore = useCurrentOfferPositionGroupsStore();

const { offerPositionGroups } = storeToRefs(opgStore);

const selectedGroups = computed(
  () =>
    offerPositionGroups.value?.filter((group) =>
      props.selectedGroupIds.includes(group.id)
    ) || []
);

const showAddSupplierRfq = computed(() => {
  return selectedGroups.value.some((pos) => !pos.showSupplierRfqs);
});

const showCancelSupplierRfq = computed(() => {
  return selectedGroups.value.some((pos) => pos.showSupplierRfqs);
});

async function updateShowSupplierRfqs(showSupplierRfqs: boolean) {
  emit("update-show-supplier-rfqs", showSupplierRfqs);
  if (showSupplierRfqs) {
    await opgStore.showSupplierRfqs(
      selectedGroups.value.map((group) => group.id)
    );
  } else {
    await opgStore.hideSupplierRfqs(
      selectedGroups.value.map((group) => group.id)
    );
  }
}
</script>
