<template>
  <q-btn icon="sym_r_swap_vert" :label="$t('Sort')" class="font-weight-inherit">
    <q-menu no-route-dismiss :offset="[0, 2]">
      <q-list dense>
        <q-item
          clickable
          v-ripple
          v-for="option in sortOptions"
          :key="option.value"
          active-class="text-primary-7 bg-primary-1"
          :active="sortBy == option.value"
        >
          <q-item-section>
            <div class="row items-center">
              <q-icon :name="option.icon" size="xs" class="q-mr-sm" />
              {{ $t(`inboxPage.sortOptions.${option.label}`) }}
            </div>
          </q-item-section>
          <q-menu anchor="top right" self="top left" no-route-dismiss>
            <q-list dense>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="
                  $emit('updateSort', {
                    sortBy: option.value,
                    descending: false,
                  })
                "
                active-class="text-primary-7 bg-primary-1"
                :active="sortBy == option.value && !descending"
              >
                <q-item-section>
                  <div class="row items-center">
                    <q-icon
                      name="sym_r_arrow_upward"
                      size="xs"
                      class="q-mr-sm"
                    />
                    {{ $t("inboxPage.ascending") }}
                  </div>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="
                  $emit('updateSort', {
                    sortBy: option.value,
                    descending: true,
                  })
                "
                active-class="text-primary-7 bg-primary-1"
                :active="sortBy == option.value && descending"
              >
                <q-item-section>
                  <div class="row items-center">
                    <q-icon
                      name="sym_r_arrow_downward"
                      size="xs"
                      class="q-mr-sm"
                    />
                    {{ $t("inboxPage.descending") }}
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
defineProps<{
  sortBy: string | null;
  descending: boolean | null;
}>();

defineEmits<{
  updateSort: [sort: { sortBy: string; descending: boolean }];
}>();

const sortOptions = [
  { value: "short_code", label: "id", icon: "sym_r_qr_code_2" },
  { value: "created_at", label: "created_at", icon: "sym_r_event" },
  { value: "status", label: "status", icon: "sym_r_assignment" },
  { value: "assigned_user_id", label: "assigned_user", icon: "sym_r_person" },
  {
    value: "requested_submission_date",
    label: "requested_submission_date",
    icon: "sym_r_calendar_clock",
  },
  {
    value: "requested_customer_submission_date",
    label: "requested_customer_submission_date",
    icon: "sym_r_event_busy",
  },
  { value: "offered", label: "offered_positions", icon: "sym_r_work" },
  { value: "progress", label: "progress", icon: "sym_r_timeline" },
];
</script>
