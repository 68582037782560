<template>
  <q-page class="fit" :style-fn="() => ''">
    <div
      v-if="is404"
      class="fit row items-center justify-center text-lg text-neutral-7"
    >
      {{ $t("inquiryPage.notFound") }}
    </div>
    <template v-else>
      <q-splitter v-model="pageSplitPercentage" class="full-height">
        <template #before>
          <header-bar>
            <template #right>
              <q-btn
                dense
                flat
                size="md"
                icon="sym_r_chevron_left"
                color="neutral-7"
                class="no-link-decoration"
                :to="{ name: 'inquiry' }"
              >
                <q-tooltip>
                  {{ $t("inquiryPositionsPage.backToInquiryButton") }}
                </q-tooltip>
              </q-btn>
              <inquiry-navigation />
              <q-btn
                v-if="organization?.useSupplierData"
                dense
                flat
                size="md"
                :icon="icons.supplierRfq"
                color="neutral-7"
                class="no-link-decoration"
                :to="{
                  name: 'supplier-rfqs',
                  query: {
                    inquiryId,
                    inquiryShortCodeForFilteredId: inquiry?.shortCode,
                  },
                }"
              >
                <q-tooltip>
                  {{ $t("inquiryPositionsPage.toSupplierRequestsButton") }}
                </q-tooltip>
              </q-btn>
            </template>
          </header-bar>
          <positions-table ref="positionsTable" />
        </template>
        <template #after>
          <inquiry-header-bar-right />
          <documents-view ref="documentsView" />
        </template>
      </q-splitter>
    </template>
  </q-page>
</template>

<script setup lang="ts">
import HeaderBar from "@/components/Header/HeaderBar.vue";
import InquiryHeaderBarRight from "@/components/InquiryHeaderBarRight.vue";
import InquiryNavigation from "@/components/InquiryNavigation.vue";
import DocumentsView from "@/components/InquiryPositionsPage/DocumentsView.vue";
import PositionsTable from "@/components/InquiryPositionsPage/PositionsTable.vue";
import { providePositionsEvents } from "@/composables/usePositionsEvents";
import { useRouteParams } from "@/composables/useRouteParams";
import { provideSelectedPositionIds } from "@/composables/useSelectedPositionIds";
import icons from "@/config/icons";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const pageSplitPercentage = ref(50);

const { organization } = storeToRefs(useCurrentOrganizationStore());

const { inquiryId } = useRouteParams();

const positionsTable = ref<InstanceType<typeof PositionsTable> | null>(null);
const documentsView = ref<InstanceType<typeof DocumentsView> | null>(null);

providePositionsEvents(); // provide typed event bus for child components

const selectedPositionIds = ref<number[]>([]);
provideSelectedPositionIds(selectedPositionIds);

const { is404, inquiry } = storeToRefs(useCurrentInquiryStore());
</script>

<style scoped lang="scss">
.no-link-decoration {
  font-weight: inherit;

  &:hover {
    text-decoration: none;
  }
}
</style>
