<template>
  <pdf-viewer v-if="documentFile" :file="documentFile" />
</template>

<script setup lang="ts">
import { getDocumentFile } from "@/api/document";
import PdfViewer from "@/components/PdfViewer/PdfViewer.vue";
import type { Inbox } from "@/types/inbox";
import type { Inquiry } from "@/types/inquiry";
import { ref, watch } from "vue";

const props = defineProps<{
  inbox: Inbox;
  inquiry: Inquiry;
}>();

const documentFile = ref<File | null>(null);

async function loadData() {
  if (!props.inquiry.documents.length) return;

  const firstDocumentId = props.inquiry.documents[0].id;
  const result = await getDocumentFile(
    props.inbox.id,
    props.inquiry.id,
    firstDocumentId
  );
  if (!result) return;
  documentFile.value = result;
}
watch(
  () => props.inquiry.id,
  async () => {
    documentFile.value = null;
    await loadData();
  },
  { immediate: true }
);
</script>
