<script setup lang="ts">
import { useConfirmUnsavedChanges } from "@/composables/useConfirmUnsavedChanges";
import { RouterView } from "vue-router";

useConfirmUnsavedChanges();
</script>

<template>
  <router-view />
</template>
