<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="general" />
    <q-form @submit="saveInbox" class="column gap-y-sm q-pa-md">
      <q-input
        v-if="inbox"
        dense
        outlined
        autofocus
        name="name"
        v-model="inbox.name"
        :readonly="!canWrite"
        :label="$t('Name')"
        lazy-rules
        :rules="[rules.name]"
      />
      <q-skeleton type="QInput" v-else />
      <q-input
        v-if="inbox"
        dense
        outlined
        name="description"
        v-model="inbox.description"
        :readonly="!canWrite"
        :label="$t('Description')"
      />
      <q-input
        maxlength="5"
        minlength="2"
        v-if="inbox"
        dense
        outlined
        input-class="text-uppercase"
        name="shortCode"
        v-model="inbox.shortCode"
        :readonly="!canWrite"
        :label="$t('inbox.shortCode')"
        lazy-rules
        :rules="[rules.shortCode]"
      />
      <q-skeleton type="QInput" v-else />
      <display-dates-select
        v-if="inbox"
        :readonly="!canWrite"
        v-model:displayDates="inbox.displayDates"
      />
      <q-skeleton type="QInput" v-else />
      <div class="row justify-between q-mt-lg">
        <q-btn
          v-if="canWrite"
          dense
          flat
          color="neutral-7"
          class="float-right q-mt-md"
          @click="deleteInbox"
          :label="$t('settings.inbox.general.deleteInbox')"
          :disable="!inbox || isUpdating"
          :loading="isDeleting"
        />

        <q-btn
          v-if="canWrite"
          dense
          class="float-right q-mt-md"
          type="submit"
          color="primary"
          :label="$t('settings.inbox.general.saveInbox')"
          :disable="!inbox || !canBeSubmitted || isDeleting"
          :loading="isUpdating"
        />
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import DisplayDatesSelect from "@/components/Settings/Inbox/DisplayDatesSelect.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useInboxFormValidation } from "@/composables/formValidation/useInboxFormValidation";
import { useRouteParams } from "@/composables/useRouteParams";
import router from "@/router";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { useInboxesStore } from "@/stores/inboxes";
import type { Inbox } from "@/types/inbox";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const store = useInboxesStore();
const { inboxes } = storeToRefs(store);

const { inboxId } = useRouteParams();

const isUpdating = ref(false);
const isDeleting = ref(false);

const { hasCurrentInboxRole } = storeToRefs(useCurrentPermissionsStore());
const canWrite = computed(() => hasCurrentInboxRole.value["inbox_admin"]);

const inbox = ref<Inbox | null>(null);
watch(
  () => [inboxes.value, inboxId.value],
  async () => {
    inbox.value = await store.getInbox(inboxId.value);
  },
  { immediate: true }
);

const name = computed(() => inbox.value?.name || null);
const shortCode = computed(() => inbox.value?.shortCode || null);
const { rules, canBeSubmitted } = useInboxFormValidation(name, shortCode);

async function saveInbox() {
  if (!inbox.value) return;
  isUpdating.value = true;
  try {
    await store.updateInbox(inbox.value.id, inbox.value);
    q.notify({
      message: t("settings.inbox.general.notifySaved"),
      color: "positive",
      actions: [{ label: t("OK"), color: "white" }],
    });
  } catch (e: any) {
    let errors: Object[] = [];
    if (e.response.data.shortCode)
      errors = errors.concat(e.response.data.shortCode);
    if (e.response.data.description)
      errors = errors.concat(e.response.data.description);
    if (e.response.data.name) errors = errors.concat(e.response.data.name);
    if (e.response.data.nonFieldErrors)
      errors = errors.concat(e.response.data.nonFieldErrors);

    const message = errors ? errors.join("\n") : "Error updating inbox";
    q.notify({
      message: t(message),
      color: "negative",
    });
  } finally {
    isUpdating.value = false;
  }
}

async function deleteInbox() {
  q.dialog({
    title: t("settings.inbox.general.confirmDelete.title"),
    message: t("settings.inbox.general.confirmDelete.message"),
    persistent: true,
    ok: {
      color: "negative",
      label: t("settings.inbox.general.confirmDelete.ok"),
    },
    cancel: {
      flat: true,
      color: "neutral-7",
    },
  }).onOk(async () => {
    if (!inbox.value) return;
    isDeleting.value = true;
    try {
      await store.deleteInbox(inbox.value.id);
      q.notify({
        message: t("settings.inbox.general.notifyDeleted"),
        actions: [{ label: t("OK"), color: "white" }],
      });
      router.push({ name: "settings-organization-general" });
    } finally {
      isDeleting.value = false;
    }
  });
}
</script>
