<template>
  <div class="slim-page">
    <settings-page-header section="account" page="profile" />
    <div class="q-pa-md">
      <q-form @submit="updateUser" class="q-gutter-y-sm">
        <q-input
          v-if="user"
          outlined
          dense
          autofocus
          name="firstName"
          v-model="user.firstName"
          :label="$t('user.firstName')"
        />
        <q-skeleton type="QInput" v-else />
        <q-input
          v-if="user"
          outlined
          dense
          name="lastName"
          v-model="user.lastName"
          :label="$t('user.lastName')"
        />
        <q-skeleton type="QInput" v-else />
        <q-input
          v-if="user"
          outlined
          dense
          name="email"
          v-model="user.email"
          :label="$t('user.email')"
        />
        <q-skeleton type="QInput" v-else />
        <div class="row justify-end">
          <q-btn
            dense
            type="submit"
            color="primary"
            :label="$t('Save')"
            :disable="!user"
            :loading="isUpdatingUser"
          />
        </div>
      </q-form>
      <q-separator class="q-my-md" />
      <div class="row items-center justify-between">
        <div>
          <div class="text-subtitle1">
            {{ $t("settings.account.profile.deleteAccount") }}
          </div>
          <div class="text-neutral-8">
            {{ $t("settings.account.profile.deleteAccountDescription") }}
          </div>
        </div>
        <q-btn
          dense
          class="q-my-sm"
          :label="$t('settings.account.profile.deleteAccount')"
          @click="deleteUser"
          :disable="!user"
          :loading="isDeletingUser"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const userStore = useCurrentUserStore();
if (!userStore.user) {
  userStore.loadUser();
}

const user = ref(userStore.user);
const isUpdatingUser = ref(false);
const isDeletingUser = ref(false);

const q = useQuasar();
const { t } = useI18n();
const router = useRouter();

watch(
  () => userStore.user,
  (newUser) => {
    user.value = newUser;
  }
);

async function updateUser() {
  if (!user.value) return;
  isUpdatingUser.value = true;
  try {
    await userStore.updateUser(user.value);
    q.notify({
      type: "positive",
      message: t("settings.account.profile.accountSettingsUpdated"),
    });
  } finally {
    isUpdatingUser.value = false;
  }
}

function deleteUser() {
  q.dialog({
    title: t("settings.account.profile.confirmDelete.title"),
    message: t("settings.account.profile.confirmDelete.message"),
    ok: {
      color: "negative",
    },
    cancel: {
      flat: true,
      color: "neutral-10",
    },
    persistent: true,
  }).onOk(doDeleteUser);
}

async function doDeleteUser() {
  isDeletingUser.value = true;
  try {
    await userStore.deleteUser();
    q.notify({
      type: "positive",
      message: t("settings.account.profile.accountDeleted"),
    });
    router.push({ name: "login" });
  } finally {
    isDeletingUser.value = false;
  }
}
</script>
