import {
  updateOrganization as apiUpdateOrganization,
  readOrganization,
} from "@/api/organization";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Organization } from "@/types/organization";
import * as Sentry from "@sentry/browser";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useCurrentOrganizationStore = defineStore("organization", () => {
  const organization = ref<Organization | null>(null);

  const { organizationId } = useRouteParams();

  const is404 = ref(false);

  async function loadOrganization() {
    if (isNaN(organizationId.value)) return;
    try {
      organization.value = await readOrganization(organizationId.value);
    } catch (error: any) {
      if (error.response?.status === 404) {
        is404.value = true;
        return;
      } else {
        throw error;
      }
    }

    is404.value = false;
    Sentry.setContext("organization", {
      id: organization.value.id,
      name: organization.value.name,
    });
    Sentry.setTag("organization_id", organization.value.id.toString());
  }

  watch(organizationId, loadOrganization, { immediate: true });

  async function updateOrganization(newOrganization: Organization) {
    organization.value = newOrganization;
    organization.value = await apiUpdateOrganization(newOrganization);
  }

  return {
    organization,
    is404,
    loadOrganization,
    updateOrganization,
  };
});
