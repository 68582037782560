<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit">
        <q-card-section>
          <q-input
            dense
            outlined
            :disable="isLoading"
            v-model="firstName"
            lazy-rules
            :label="$t('user.firstName')"
            :rules="[
              (val) =>
                (val && val.length > 0) || $t('This field must not be empty.'),
            ]"
          />
          <q-input
            dense
            outlined
            :disable="isLoading"
            v-model="lastName"
            lazy-rules
            :label="$t('user.lastName')"
            :rules="[
              (val) =>
                (val && val.length > 0) || $t('This field must not be empty.'),
            ]"
          />
          <q-input
            dense
            outlined
            :disable="isLoading"
            v-model="email"
            type="email"
            :label="$t('user.email')"
            lazy-rules
            :rules="[
              'email',
              (val) =>
                (val && val.length > 0) || $t('This field must not be empty.'),
            ]"
          />
          <q-input
            dense
            outlined
            v-if="isCreateMode"
            :disable="isLoading"
            v-model="password"
            lazy-rules
            type="password"
            :label="$t('user.password')"
            :rules="[
              (val) =>
                (val && val.length > 0) || $t('This field must not be empty.'),
            ]"
          />
        </q-card-section>
        <q-card-section>
          <inbox-select
            outlined
            v-if="isCreateMode && inboxes"
            :disable="isLoading"
            v-model="inboxesToInvite"
            :inboxes="inboxes"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            dense
            outline
            v-if="!isCreateMode"
            :label="
              $t(
                'settings.organization.members.editMemberDialog.changePassword'
              )
            "
            @click="changePassword"
          />
          <q-space />
          <q-btn dense flat :label="$t('Cancel')" @click="onDialogCancel" />
          <q-btn
            dense
            color="primary"
            :disable="isLoading"
            :label="$t('Save')"
            type="submit"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isCommitting" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { postUser, readUser, resetPasswordUser, updateUser } from "@/api/user";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { ShortInbox } from "@/types/inbox";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import InboxSelect from "./InboxSelect.vue";

const q = useQuasar();
const { t } = useI18n();

const userStore = useCurrentUserStore();

const props = defineProps<{
  organizationId: number;
  userId?: number;
  inboxes?: ShortInbox[];
}>();

interface LabelNumberValue {
  label: string;
  value: number;
}

const isCreateMode = computed(() => props.userId === undefined);

const email = ref<string | undefined>();
const firstName = ref<string | undefined>();
const lastName = ref<string | undefined>();
const password = ref<string | undefined>();
const isLoading = ref(false);
const isCommitting = ref(false);

const inboxesToInvite = ref<LabelNumberValue[]>([]);

onMounted(loadData);

async function loadData() {
  if (!props.userId) return;
  isLoading.value = true;

  try {
    const user = await readUser(props.organizationId, props.userId);
    email.value = user.email;
    firstName.value = user.firstName;
    lastName.value = user.lastName;
  } finally {
    isLoading.value = false;
  }
}

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  isCommitting.value = true;

  try {
    if (props.userId) {
      await updateUser(props.organizationId, {
        id: props.userId,
        email: email.value || "",
        firstName: firstName.value || "",
        lastName: lastName.value || "",
      });
    } else {
      await postUser(props.organizationId, {
        id: 0,
        email: email.value || "",
        firstName: firstName.value || "",
        lastName: lastName.value || "",
        password: password.value || "",
        inboxIdsToInvite: inboxesToInvite.value.map((x) => x.value),
      });
    }
  } finally {
    isCommitting.value = false;
  }

  if (props.userId == userStore.user?.id) await userStore.loadUser();

  onDialogOK();
}

function changePassword() {
  q.dialog({
    title: t("settings.organization.members.editMemberDialog.changePassword"),
    message: t("settings.organization.members.editMemberDialog.newPassword"),
    prompt: {
      model: "",
      type: "password",
    },
    cancel: true,
    persistent: true,
  }).onOk((value) => {
    if (props.userId) {
      resetPasswordUser(props.organizationId, props.userId, value);
    }
  });
}
</script>
@/stores/currentUser
