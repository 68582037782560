<template>
  <div class="row no-wrap full-width sidebar-inbox-group-container">
    <div class="sidebar-menu-item-drag-handle-container">
      <q-icon
        name="sym_r_drag_indicator"
        color="neutral-7"
        class="sidebar-menu-item-drag-handle"
      />
    </div>
    <q-expansion-item
      v-model="isExpanded"
      dense
      class="full-width"
      header-class="no-focus-helper sidebar-inbox-group"
    >
      <template v-slot:header>
        <q-item-section>
          <div class="row no-wrap">
            <div class="sidebar-menu-item-avatar">
              <q-icon name="sym_r_inbox" size="20px" />
            </div>
            <div class="sidebar-menu-item-label" :title="inbox.name">
              {{ inbox.name }}
            </div>
          </div>
        </q-item-section>
      </template>
      <sidebar-menu-item
        v-for="inboxView in inboxViews"
        :key="inboxView.label"
        :to="inboxView.link.value"
        :label="$t(`inboxView_${inboxView.label}`)"
        :is-active="
          () => isInboxViewActive(inboxView.link.value.params.inboxView)
        "
        inset
      />
    </q-expansion-item>
  </div>
</template>

<script setup lang="ts">
import { useInboxViews } from "@/composables/useInboxViews";
import { useRouteParams } from "@/composables/useRouteParams";
import type { ShortInbox } from "@/types/inbox";
import { persistentRef } from "@/utils/persistentRef";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";
import SidebarMenuItem from "./SidebarMenuItem.vue";

const props = defineProps<{
  inbox: ShortInbox;
}>();

const { getInboxViews } = useInboxViews();
const inboxViews = computed(() => getInboxViews(props.inbox));

const { organizationId } = useRouteParams();

const { currentRoute } = useRouter();

const isInboxActive = computed(() => {
  return currentRoute.value.path.startsWith(
    `/organizations/${organizationId.value}/inboxes/${props.inbox.id}/`
  );
});

const isExpanded = persistentRef(
  `sidebar-inbox-${props.inbox.id}-expanded`,
  isInboxActive.value
);
watch(isInboxActive, () => {
  if (isInboxActive.value) {
    isExpanded.value = true;
  }
});

function isInboxViewActive(inboxViewName: string) {
  return (
    isInboxActive.value && currentRoute.value.params.inboxView === inboxViewName
  );
}
</script>

<style lang="scss">
.sidebar-inbox-group:hover {
  background-color: $neutral-3;
}

.sidebar-menu-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sidebar-menu-item-avatar {
  width: 34px;
  margin-left: -4px;
  color: $neutral-7;
  flex-shrink: 0;
}

.sidebar-menu-item-drag-handle {
  cursor: move;
  margin-top: 9px;
  display: none;
  transform: translateX(-6px);
  font-size: 16px;
}

.sidebar-inbox-group-container:hover .sidebar-menu-item-drag-handle {
  display: block;
}
</style>
