<template>
  <q-btn
    dense
    flat
    icon="sym_r_dock_to_right"
    color="neutral-10"
    @click="toggle"
  />
</template>

<script setup lang="ts">
import { useSidebarStateStore } from "@/stores/sidebarState";

const { toggle } = useSidebarStateStore();
</script>
