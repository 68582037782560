<template>
  <q-card
    class="q-pa-md row no-wrap gap-sm items-start full-width"
    :id="`inquiry-history-entry-${entry.id}`"
  >
    <div class="col col-auto">
      <user-avatar :user="entry.user" />
    </div>
    <div class="col full-width column no-wrap gap-sm">
      <div class="row no-wrap gap-sm items-center">
        <div>
          <span class="history-highlight">
            <user-label :user="entry.user" />
          </span>
          <span class="text-xs text-neutral-7">
            &bull;
            {{ formatDistanceToNow(entry.createdAt, locale) }}
            <q-tooltip> {{ $d(entry.createdAt, "longDatetime") }} </q-tooltip>
          </span>
        </div>
        <q-space />
        <div class="row no-wrap gap-xs">
          <q-btn
            v-if="entry.user.id === user?.id"
            flat
            dense
            icon="sym_r_edit"
            color="neutral-7"
            size="sm"
            @click="editComment"
          >
            <q-tooltip>{{
              $t("inquiryPage.inquiryHistory.editComment")
            }}</q-tooltip>
          </q-btn>
          <q-btn
            v-if="
              entry.user.id === user?.id ||
              isCurrentOrganizationAdmin ||
              isSuperUser
            "
            flat
            dense
            icon="sym_r_delete"
            color="neutral-7"
            size="sm"
            :loading="isDeleting"
            @click="confirmDeleteComment"
          >
            <q-tooltip>{{
              $t("inquiryPage.inquiryHistory.deleteComment")
            }}</q-tooltip>
          </q-btn>
        </div>
      </div>
      <comment-editor
        ref="commentEditorEl"
        v-model="bodyContent"
        placeholder=""
        :editable="isEditing"
        class="break-word full-width"
      />
      <div v-if="!isEditing && entry.extraData.editedAt">
        <span class="text-2xs text-neutral-7"
          >{{
            $t("inquiryPage.inquiryHistory.editedAt", {
              date: formatDistanceToNow(entry.extraData.editedAt, locale),
            })
          }}
          <q-tooltip>
            {{ $d(entry.extraData.editedAt, "longDatetime") }}
          </q-tooltip>
        </span>
      </div>
      <div v-if="isEditing" class="row justify-end items-center gap-sm q-mt-sm">
        <q-btn
          flat
          dense
          text-color="neutral-7"
          :label="$t('Cancel')"
          :disable="isSaving"
          @click="
            () => {
              isEditing = false;
              bodyContent = entry.extraData.bodyContent;
            }
          "
        />
        <q-btn
          dense
          text-color="neutral-7"
          :label="$t('Save')"
          :disable="!bodyContent || isSaving"
          :loading="isSaving"
          @click="saveComment"
        />
      </div>
    </div>
  </q-card>
</template>

<script setup lang="ts">
import UserAvatar from "@/components/User/UserAvatar.vue";
import UserLabel from "@/components/User/UserLabel.vue";
import { formatDistanceToNow } from "@/i18n/formatDistanceToNow";
import { useCurrentInquiryHistoryStore } from "@/stores/currentInquiryHistory";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { InquiryHistoryEntryComment } from "@/types/inquiryHistory";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import CommentEditor from "./CommentEditor/CommentEditor.vue";

const props = defineProps<{
  entry: InquiryHistoryEntryComment;
}>();

const commentEditorEl = ref<typeof CommentEditor | null>(null);

const { t, locale } = useI18n();
const q = useQuasar();

const { user, isCurrentOrganizationAdmin, isSuperUser } = storeToRefs(
  useCurrentUserStore()
);

const bodyContent = ref(props.entry.extraData.bodyContent);
watch(
  () => props.entry.extraData.bodyContent,
  (newBodyContent) => {
    bodyContent.value = newBodyContent;
  }
);

const isEditing = ref(false);
const isSaving = ref(false);

const store = useCurrentInquiryHistoryStore();
const isDeleting = ref(false);

function confirmDeleteComment() {
  q.dialog({
    title: t("inquiryPage.inquiryHistory.confirmDeleteComment.title"),
    message: t("inquiryPage.inquiryHistory.confirmDeleteComment.message"),
    ok: {
      color: "negative",
      dense: true,
      label: t("inquiryPage.inquiryHistory.confirmDeleteComment.ok"),
    },
    cancel: {
      flat: true,
      dense: true,
      color: "neutral-7",
    },
    persistent: true,
  }).onOk(async () => {
    await deleteComment();
  });
}

async function deleteComment() {
  isDeleting.value = true;
  try {
    await store.deleteComment(props.entry.id);
  } finally {
    isDeleting.value = false;
  }
}

async function editComment() {
  isEditing.value = true;
  await nextTick();
  commentEditorEl.value?.focus();
}

async function saveComment() {
  isSaving.value = true;
  try {
    await store.updateComment(props.entry.id, bodyContent.value);
  } finally {
    isSaving.value = false;
    isEditing.value = false;
  }
}
</script>
