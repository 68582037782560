<template>
  <q-select
    use-input
    dense
    input-debounce="0"
    class="text-left"
    v-model="selectedTags"
    multiple
    use-chips
    :options="availableTags"
    @remove="emit('remove-tag', $event.value)"
    @add="emit('add-tag', $event.value)"
    @filter="filterTags"
    :label="showLabel ? t('Tags') : undefined"
  >
    <slot />
    <template #selected-item="scope">
      <tag-chip
        :tag="scope.opt"
        removable
        @remove="scope.removeAtIndex(scope.index)"
      />
    </template>
    <template #option="scope">
      <q-item
        v-ripple
        clickable
        :class="scope.selected ? 'bg-neutral-2' : ''"
        dense
        @click="scope.toggleOption(scope.opt)"
      >
        <q-item-section class="item-section-row">
          <q-checkbox
            size="sm"
            dense
            class="q-mr-xs"
            v-model="scope.selected"
            @click="scope.toggleOption(scope.opt)"
          />
          <div>
            <tag-chip :tag="scope.opt" />
          </div>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import type { Tag } from "@/types/tag";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import TagChip from "./TagChip.vue";

const { t } = useI18n();

const props = defineProps<{
  available: Tag[];
  selected: Tag[];
  showLabel?: boolean;
}>();

const emit = defineEmits<{
  (event: "add-tag", value: Tag): void;
  (event: "remove-tag", value: Tag): void;
}>();

const selectedTags = ref(props.selected);
const availableTags = ref(props.available);
function filterTags(
  search: string,
  update: (callbackFn: () => void, ref?: any) => void
) {
  if (search === "") {
    update(() => (availableTags.value = props.available));
    return;
  }

  update(
    () => {
      availableTags.value = props.available.filter(
        (tag) => tag.label.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    (ref: any) => {
      if (search !== "" && ref.options.length > 0) {
        ref.setOptionIndex(-1); // reset optionIndex in case there is something selected
        ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
      }
    }
  );
}
</script>

<style scoped lang="scss">
.item-section-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>
