<template>
  <q-menu
    ref="menuEl"
    @hide="$emit('update:notes', notesInternal)"
    @keydown.stop
    @keydown.stop.escape="menuEl?.hide()"
  >
    <q-card>
      <q-card-section>
        <q-input
          autofocus
          dense
          outlined
          :disable="disabled"
          type="textarea"
          v-model="notesInternal"
          class="notes-input"
        />
      </q-card-section>
    </q-card>
  </q-menu>
</template>

<script setup lang="ts">
import { QMenu } from "quasar";
import { ref } from "vue";

const props = defineProps<{
  notes: string;
  disabled?: boolean;
}>();

defineEmits(["update:notes"]);

const notesInternal = ref(props.notes);

const menuEl = ref<QMenu | null>(null);
</script>

<style scoped lang="scss">
.notes-input {
  font-size: small;
  width: 500px;
}
</style>
