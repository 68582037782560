import type { Document } from "@/types/document";
import type { Email } from "@/types/email";
import { isAxiosError } from "axios";
import { client, clientWithoutErrorNotifications } from "./client/client";

export type ApiDocument = Omit<Document, "createdAt" | "email"> & {
  createdAt: string;
  email: ApiEmail;
};

export type ApiEmail = Omit<Email, "sentAt"> & {
  sentAt: string;
};

export async function getDocumentFile(
  inboxId: number,
  inquiryId: number,
  documentId: number
): Promise<File | undefined> {
  try {
    const response = await client.get(
      `/inboxes/${inboxId}/inquiries/${inquiryId}/documents/${documentId}/download/`,
      { responseType: "blob" }
    );
    const blob = new Blob([response.data], {
      type: response.headers["contentType"],
    });
    return new File([blob], response.headers["filename"], {
      type: response.headers["contentType"] as string,
    });
  } catch (error: unknown) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 404
    ) {
      return undefined;
    }
    throw error;
  }
}

export async function downloadDocument(
  inboxId: number,
  inquiryId: number,
  documentId: number
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/${inquiryId}/documents/${documentId}/download/`,
    "_blank"
  );
}

export async function uploadDocument(
  inboxId: number,
  file: File,
  notifyOnError = true
): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);
  const url = `/inboxes/${inboxId}/inquiries/`;

  const chosenClient = notifyOnError ? client : clientWithoutErrorNotifications;
  await chosenClient.post(url, formData);
}

export async function moveDocumentToLead(
  inboxId: number,
  documentId: number,
  inquiryId: number,
  leadToMoveDocumentToId: number,
  keepInCurrentLead: boolean
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/documents/${documentId}/move_to_new_lead/`,
    {
      new_lead_id: leadToMoveDocumentToId,
      keep_in_current_lead: keepInCurrentLead,
    }
  );
}
