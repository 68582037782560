<template>
  <q-separator />
  <div class="q-pb-md">
    <div class="row no-wrap justify-between">
      <div class="text-neutral-7 text-base">
        {{ $t("inquiryPage.inquiryHistory.title") }}
      </div>
      <inquiry-subscribe-button />
    </div>
    <div class="q-py-md">
      <inquiry-history-skeletons v-if="isLoading" />
      <div v-else class="column gap-xs">
        <template v-for="(entry, idx) in inquiryHistory" :key="entry.id">
          <inquiry-history-separator v-if="idx > 0" />
          <inquiry-history-comment
            v-if="entry.type === 'COMMENT'"
            :entry="entry"
          />
          <inquiry-history-entry v-else :entry="entry" />
        </template>
      </div>
    </div>
  </div>
  <div class="q-pb-md">
    <add-comment-box />
  </div>
</template>

<style lang="scss">
.history-highlight {
  color: $neutral-10;
  font-weight: 600;
}
</style>

<script setup lang="ts">
import { getInquiryHistory } from "@/api/inquiryHistory";
import InquirySubscribeButton from "@/components/InquiryPage/InquirySubscribeButton.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentInquiryHistoryStore } from "@/stores/currentInquiryHistory";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRouter } from "vue-router";
import AddCommentBox from "./AddCommentBox.vue";
import InquiryHistoryComment from "./InquiryHistoryComment.vue";
import InquiryHistoryEntry from "./InquiryHistoryEntry.vue";
import InquiryHistorySeparator from "./InquiryHistorySeparator.vue";
import InquiryHistorySkeletons from "./InquiryHistorySkeletons.vue";

const { inquiryHistory, isLoading } = storeToRefs(
  useCurrentInquiryHistoryStore()
);

const { organizationId, inboxId, inquiryId } = useRouteParams();

const { inquiry } = storeToRefs(useCurrentInquiryStore());

async function update() {
  inquiryHistory.value = [];
  isLoading.value = true;
  const history = await getInquiryHistory(
    organizationId.value,
    inboxId.value,
    inquiryId.value
  );
  history.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  inquiryHistory.value = history;
  isLoading.value = false;

  scrollToEntryHash();
}
watch([organizationId, inboxId, inquiryId, inquiry], update, {
  immediate: true,
});

const { currentRoute } = useRouter();

function scrollToEntryHash() {
  const { hash } = currentRoute.value;
  if (hash) {
    const entry = document.querySelector(hash);
    if (entry) {
      entry.scrollIntoView();
    }
  }
}
</script>
