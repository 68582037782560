<template>
  <div class="pdf-viewer">
    <div
      ref="scrollElement"
      class="scroll-wrapper"
      @scroll.passive="debouncedHandleScroll"
    >
      <div class="header-wrapper">
        <q-card class="header">
          <div class="q-mx-md row items-baseline">
            <page-number-input
              :current-page="currentPage"
              @page-changed="scrollToPage"
            />
            &nbsp; /&nbsp;<span>{{ totalNumPages }}</span>
          </div>
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_rotate_90_degrees_ccw"
            @click.prevent.stop="rotateCcw"
            data-testid="pdf-viewer-rotate-ccw-button"
          />
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_rotate_90_degrees_cw"
            @click.prevent.stop="rotateCw"
            data-testid="pdf-viewer-rotate-cw-button"
          />
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_zoom_out"
            @click.prevent.stop="zoomOut"
          />
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_zoom_in"
            @click.prevent.stop="zoomIn"
          />
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_width"
            @click.prevent.stop="setFullWidthScale"
          />
          <q-btn
            color="neutral-5"
            flat
            dense
            icon="sym_r_height"
            @click.prevent.stop="setFullPageScale"
          />
          <slot name="header"></slot>
        </q-card>
      </div>

      <pdf-document
        :file="file"
        :scale="scale"
        :rotation="rotation"
        :highlights="highlights || []"
        :aids="aids || []"
        :ocr-result="ocrResult"
        :scroll-parent="(scrollElement as HTMLElement)"
        @update-scale="updateScaleFromPdfDocument"
        @update-num-pages="(numPages) => (totalNumPages = numPages)"
        ref="pdfDocument"
      >
        <template #highlight="{ highlight, canvasSize }">
          <slot
            name="highlight"
            :highlight="highlight"
            :rotation="rotation"
            :canvasSize="canvasSize"
          />
        </template>
        <template #aid="{ aid, canvasSize }">
          <slot
            name="aid"
            :aid="aid"
            :rotation="rotation"
            :canvasSize="canvasSize"
          />
        </template>
        <template #focus-overlay="{ highlights }">
          <slot name="focus-overlay" :highlights="highlights" />
        </template>
      </pdf-document>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OCRResult } from "@/types/ocrResult";
import { isVisible } from "@/utils/display";
import debounce from "lodash/debounce";
import { nextTick, ref, watch } from "vue";
import PageNumberInput from "./PageNumberInput.vue";
import PdfDocument from "./PdfDocument.vue";

import "pdfjs-dist/web/pdf_viewer.css";
import type { AidHighlight, DisplayHighlight } from "./types";

const ZOOM_STEP = 0.1;
const SCROLL_HANDLER_DEBOUNCE_WAIT = 100; // ms

const props = withDefaults(
  defineProps<{
    file: File;
    highlights: DisplayHighlight[];
    aids: AidHighlight[];
    ocrResult: OCRResult | null;
  }>(),
  {
    highlights: () => [],
    aids: () => [],
    ocrResult: null,
  }
);
const scale = ref(1);
const rotation = ref(0);
const pdfDocument = ref<typeof PdfDocument | undefined>(undefined);
const scrollElement = ref<HTMLElement | undefined>();
const currentPage = ref(1);
const totalNumPages = ref<number | undefined>(undefined);

// pdf.js uses CSS transforms to scale the text selection layer
// We need to update the CSS variable to match the scale
function updateScaleCss() {
  if (!scrollElement.value) return;
  scrollElement.value.style.setProperty("--scale-factor", String(scale.value));
}
watch(scale, updateScaleCss);

function updateScaleFromPdfDocument(value: number) {
  const relativeZoom = value / scale.value;
  scale.value = value;
  if (relativeZoom < 1) {
    scrollFollowZoom(relativeZoom);
  } else {
    // give the window time to grow before scrolling
    // otherwise, the target position might be larger than the scrollable area
    nextTick(() => scrollFollowZoom(relativeZoom));
  }
}

function zoomIn() {
  scale.value = scale.value + ZOOM_STEP;
  const relativeZoom = scale.value / (scale.value - ZOOM_STEP);
  scrollFollowZoom(relativeZoom);
}

function zoomOut() {
  scale.value = scale.value - ZOOM_STEP;
  const relativeZoom = scale.value / (scale.value + ZOOM_STEP);
  scrollFollowZoom(relativeZoom);
}

function rotateCw() {
  rotation.value = (rotation.value + 90) % 360;
}

function rotateCcw() {
  rotation.value = (rotation.value + 270) % 360;
}

/**
 * Update scroll values after zoom, otherwise when the content grows, the visible window moves
 * upwards relative to the content
 */
function scrollFollowZoom(relativeZoom: number) {
  if (!scrollElement.value) return;
  const newScrollLeft = scrollElement.value.scrollLeft * relativeZoom;
  const newScrollTop = scrollElement.value.scrollTop * relativeZoom;
  scrollElement.value.scroll(newScrollLeft, newScrollTop);
}

watch(rotation, () => nextTick(setFullPageScale));

function setFullWidthScale() {
  if (!pdfDocument.value) return;
  pdfDocument.value.setFullWidthScale();
}

function setFullPageScale() {
  if (!pdfDocument.value) return;
  pdfDocument.value.setFullPageScale();
}

watch(() => props.file, resetDisplay);

function resetDisplay() {
  rotation.value = 0;
  scrollElement.value?.scroll(0, 0);
}

function handleScroll() {
  updateCurrentPageNumber();
}

function updateCurrentPageNumber() {
  const firstVisiblePage = getFirstVisiblePageNumberOrNull();
  if (firstVisiblePage === null) return;
  currentPage.value = firstVisiblePage;
}

function getFirstVisiblePageNumberOrNull() {
  if (!scrollElement.value) return null;

  const pageElements = getPageElements();

  for (const [idx, pageElement] of pageElements.entries()) {
    if (isVisible(pageElement, scrollElement.value)) return idx + 1;
  }
  return null;
}

const debouncedHandleScroll = debounce(
  handleScroll,
  SCROLL_HANDLER_DEBOUNCE_WAIT
);

function scrollToPage(pageNumber: number) {
  const pageElements = getPageElements();

  if (pageNumber < 1 || pageNumber > pageElements.length) return;
  pageElements[pageNumber - 1].scrollIntoView();
}

function getPageElements() {
  return new Array(...document.getElementsByClassName("pdf-page-wrapper"));
}
</script>

<style lang="scss">
.pdf-viewer {
  $header-height: 50px;

  overflow: hidden;
  position: relative;
  background-color: $document-background;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  .header-wrapper {
    left: 0;
    top: 0;
    height: $header-height;
    padding: 0.5rem;
    position: absolute;
    background: transparent;
    z-index: 200;
  }

  .header {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    color: $neutral-6;

    input {
      color: $neutral-6;
    }

    &:hover,
    &:focus-within {
      opacity: 100%;
      color: $font-color;

      input {
        color: $font-color;
      }

      .q-btn {
        color: $neutral-7;
      }
    }

    @media print {
      display: none;
    }
  }

  .scroll-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $header-height;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    overflow: auto;

    .pdf-page {
      margin: 1rem auto;
    }
  }

  .textLayer {
    // change text selection color for pdf.js text layer

    ::-moz-selection {
      /* Code for Firefox */
      background: $primary;
    }

    ::selection {
      background: $primary;
    }
  }
}
</style>
