import { provideEventBus, useEventBus } from "./useEventBus";

type PositionsEvents = {
  tableRowClick: number;
  pdfRowClick: number;
  pdfOverlayClick: void;
};

export const providePositionsEvents = () =>
  provideEventBus<PositionsEvents>("positionsEvents");
export const usePositionsEvents = () =>
  useEventBus<PositionsEvents>("positionsEvents");
