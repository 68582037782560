<template>
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    dense
    outlined
    name="hostname"
    v-model="hostname"
    :label="$t('Hostname')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    type="number"
    dense
    outlined
    name="port"
    v-model="port"
    :label="$t('Port')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    dense
    outlined
    name="username"
    v-model="username"
    :label="$t('Username')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="privateKey"
    v-model="privateKey"
    :label="$t('Private key')"
    type="textarea"
    @update:model-value="updateConfiguration"
    autocomplete="off"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="password"
    v-model="password"
    :label="$t('Password')"
    @update:model-value="updateConfiguration"
    type="password"
    autocomplete="off"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="directoryIn"
    v-model="directoryIn"
    :label="$t(`Directory incoming ${type}s`)"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="directoryOut"
    v-model="directoryOut"
    :label="$t('Directory outgoing aleaud')"
    @update:model-value="updateConfiguration"
  />
  <q-checkbox
    class="q-mt-md"
    v-model="doDeleteOnceImported"
    :label="$t('Delete product idocs once imported')"
    @update:model-value="updateConfiguration"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { keyable } from "@/types/organization";
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";

type DataType = "product" | "customer";

const props = defineProps<{
  configuration: keyable;
  type: DataType;
}>();

const hostname = ref<string>(props.configuration.hostname);
const port = ref<number>(props.configuration.port);
const username = ref<string>(props.configuration.username);
const privateKey = ref<string>(props.configuration.privateKey);
const password = ref<string>(props.configuration.password);
const directoryIn = ref<string>(props.configuration.directoryIn);
const directoryOut = ref<string>(props.configuration.directoryOut);
const doDeleteOnceImported = ref<boolean>(
  props.configuration.doDeleteOnceImported || false
);
const { isNotEmpty } = useFormValidationRules();

const emits = defineEmits<{
  "update:configuration": [configuration: keyable];
}>();

const updateConfiguration = () => {
  emits("update:configuration", {
    hostname: hostname.value,
    port: port.value,
    username: username.value,
    privateKey: privateKey.value || null,
    password: password.value || null,
    directoryIn: directoryIn.value,
    directoryOut: directoryOut.value,
    doDeleteOnceImported: doDeleteOnceImported.value,
  });
};
</script>

<style scoped lang="scss"></style>
