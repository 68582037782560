import { inject, provide, type Ref } from "vue";

const SELECTED_POSITION_IDS = Symbol("selectedPositionIds");

export function provideSelectedPositionIds(selectedPositionIds: Ref<number[]>) {
  provide(SELECTED_POSITION_IDS, selectedPositionIds);
}

export function useSelectedPositionIds() {
  const selectedPositionIds = inject(SELECTED_POSITION_IDS) as Ref<number[]>;
  if (!selectedPositionIds) {
    throw new Error(
      "useSelectedPositionIds must be used within a component that has provided selectedPositionIds"
    );
  }
  return selectedPositionIds;
}
