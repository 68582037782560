<template>
  <div class="inquiry-row">
    <div class="inquiry-row__selected">
      <q-checkbox
        size="sm"
        :model-value="selected"
        @update:model-value="$emit('update:selected', $event)"
      />
    </div>
    <div class="inquiry-row__id">{{ inquiry.shortCode }}</div>
    <div class="inquiry-row__sender">
      <div
        v-if="inquiry.email"
        class="ellipsis full-width row no-wrap items-center"
      >
        <div class="column no-wrap gap-x-xs full-width">
          <email-address-display :address="inquiry.email.sender" />
          <div class="text-neutral-7 text-xs row no-wrap items-center">
            <q-icon color="neutral-5" name="sym_r_email" class="q-mr-xs" />
            {{ getSuffix(inquiry.email.sender.email, "@") }}
          </div>
        </div>
      </div>
      <div
        v-else-if="inquiry.filenames.length"
        class="ellipsis full-width row no-wrap items-center"
      >
        <div class="column no-wrap full-width">
          <div class="ellipsis">
            {{ inquiry.filenames[0] }}
            <q-tooltip>{{ inquiry.filenames[0] }}</q-tooltip>
          </div>
          <div class="text-neutral-7 text-xs row no-wrap items-center">
            <q-icon
              color="neutral-5"
              name="sym_r_description"
              class="q-mr-xs"
            />
            {{ getSuffix(inquiry.filenames[0], ".").toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
    <div class="inquiry-row__building-project">
      {{ inquiry.buildingProject }}
    </div>
    <q-space />
    <div class="inquiry-row__tags" @click.stop>
      <tags-select-button
        :tags="inquiry.tags"
        :available-tags="inbox?.tags || []"
        @add-tag="$emit('add-tag', $event)"
        @remove-tag="$emit('remove-tag', $event)"
      />
    </div>
    <div class="inquiry-row__dates">
      <inquiry-dates-display
        :created-at="inquiry.email?.sentAt || inquiry.createdAt"
        :requested-submission-date="inquiry.requestedSubmissionDate"
        :requested-customer-submission-date="
          inquiry.requestedCustomerSubmissionDate
        "
      />
    </div>
    <div class="inquiry-row__progress">
      <inquiry-progress-display
        class="inquiry-row__progress__display"
        v-if="
          HAS_ANALYSIS_RESULTS_BASE_INQUIRY_STATUSES.includes(
            inquiry.status.originalStatus
          )
        "
        :progress="inquiry.progress"
        @click.stop
      />
      <q-btn
        dense
        color="primary"
        class="inquiry-row__progress__button no-decoration"
        size="sm"
        :to="{ name: 'inquiry-positions', params: { inquiryId: inquiry.id } }"
      >
        {{ $t("inboxPage.editPositions") }}
      </q-btn>
    </div>
    <div class="inquiry-row__status" @click.stop @keypress.stop>
      <status-select-button
        :status="inquiry.status"
        icon-only
        @update:status="(newStatus) => $emit('update:status', newStatus)"
        @retry-analysis="$emit('retry-analysis')"
      />
    </div>
    <div class="inquiry-row__assigned-to" @click.stop @keypress.stop>
      <user-select-button
        :user="inquiry.assignedUser"
        @update:user="
            (user: User | null) => $emit('update:assigned-to', user!)
          "
        :disable="!isAssignToEnabled(inquiry)"
        icon-only
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import EmailAddressDisplay from "@/components/EmailDisplay/EmailAddressDisplay.vue";
import TagsSelectButton from "@/components/Tags/TagsSelectButton.vue";
import type { Inbox } from "@/types/inbox";
import {
  HAS_ANALYSIS_RESULTS_BASE_INQUIRY_STATUSES,
  type InquiryStatus,
  type ShortInquiry,
} from "@/types/inquiry";
import type { Tag } from "@/types/tag";
import type { User } from "@/types/user";
import StatusSelectButton from "../Status/StatusSelectButton.vue";
import UserSelectButton from "../User/UserSelectButton.vue";
import InquiryDatesDisplay from "./InquiryDatesDisplay/InquiryDatesDisplay.vue";
import InquiryProgressDisplay from "./InquiryProgressDisplay.vue";

defineProps<{
  inbox: Inbox | null;
  inquiry: ShortInquiry;
  selected: boolean;
}>();

defineEmits<{
  "add-tag": [tag: Tag];
  "remove-tag": [tag: Tag];
  "update:status": [newStatus: InquiryStatus];
  "update:assigned-to": [user: User];
  "update:selected": [selected: boolean];
  "retry-analysis": [];
}>();

function isAssignToEnabled(row: ShortInquiry) {
  return !["FINALIZED"].includes(row.status.originalStatus);
}

function getSuffix(filename: string, separator: string) {
  const parts = filename.split(separator);
  if (parts.length === 1) {
    return "";
  }
  return parts[parts.length - 1];
}
</script>

<style scoped lang="scss">
.inquiry-row {
  min-height: 48px;
  padding: 0 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 32px;
  cursor: pointer;

  @media (max-width: $breakpoint-lg-max) {
    gap: 24px;
  }

  @media (max-width: $breakpoint-md-max) {
    gap: 16px;
  }

  @media (max-width: $breakpoint-sm-max) {
    gap: 8px;
  }

  &:hover {
    background-color: $neutral-1;
  }

  &__selected {
    margin-left: -8.75px; // align visible checkbox with rest of page
  }

  &__id {
    width: 62px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-sm-max) {
      width: 48px;
      font-size: $text-xs;
    }
  }

  &__sender {
    width: 200px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-lg-max) {
      width: 150px;
    }

    @media (max-width: $breakpoint-sm-max) {
      display: none;
    }
  }

  &__building-project {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;

    @media (max-width: $breakpoint-sm-max) {
      min-width: 100px;
      font-size: $text-xs;
    }
  }

  &__tags {
    flex-grow: 0;

    @media (max-width: $breakpoint-md-max) {
      display: none;
    }
  }

  &__dates {
    flex-shrink: 0;
    min-width: 140px;
  }

  &__progress {
    flex-shrink: 0;
    width: 80px;
    height: 100%;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &__button {
      display: none;
      opacity: 0;
    }

    &:hover {
      .inquiry-row__progress__button {
        display: block;
        opacity: 1;
        animation: fadeIn 0.5s;
      }

      .inquiry-row__progress__display {
        display: none;
      }
    }
  }
}
</style>
