/** This store hold information about the current app user */
import {
  deleteCurrentUser,
  resetPasswordCurrentUser,
  retrieveCurrentUserInfo,
  updateCurrentUserInfo,
} from "@/api/user";
import { useRouteParams } from "@/composables/useRouteParams";
import type { InboxRole, OrganizationRole, Role } from "@/types/role";
import type { User } from "@/types/user";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import * as Sentry from "@sentry/browser";
import type { FrontendPreferences } from "@/types/frontendPreferences";

export const useCurrentUserStore = defineStore("user", () => {
  const user = ref<User | null>(null);
  const roles = ref<Role[]>([]);
  const isSuperUser = ref(false);
  const isMemberOfMultipleOrganizations = ref(false);
  const frontendPreferences = ref<Partial<FrontendPreferences>>({});

  const organizationRoles = computed(
    () =>
      roles.value.filter(
        (role) => role.objectModel === "organization"
      ) as OrganizationRole[]
  );
  const inboxRoles = computed(
    () =>
      roles.value.filter((role) => role.objectModel === "inbox") as InboxRole[]
  );

  const { organizationId } = useRouteParams();

  const isCurrentOrganizationAdmin = computed(() => {
    return organizationRoles.value.some(
      (role) =>
        role.objectId === organizationId.value &&
        role.role === "organization_admin"
    );
  });

  const loadUser = async () => {
    let data;

    try {
      data = await retrieveCurrentUserInfo();
    } catch (error: any) {
      if (error.name === "AxiosError" && error.response?.status === 401) {
        // Ignore error 401 due to the user not being logged in
        return;
      }
      throw error;
    }

    user.value = data.user;
    roles.value = data.roles;
    isSuperUser.value = data.superUser;
    isMemberOfMultipleOrganizations.value =
      data.isMemberOfMultipleOrganizations;

    frontendPreferences.value = data.frontendPreferences;

    Sentry.setUser({
      id: user.value.id,
      email: user.value.email,
    });
  };

  const clear = () => {
    user.value = null;
    roles.value = [];
    isSuperUser.value = false;
  };

  const updateUser = async (
    changes: Partial<
      User & { frontendPreferences: Partial<FrontendPreferences> }
    >
  ) => {
    if (user.value) Object.assign(user.value, changes);
    const apiUser = await updateCurrentUserInfo(changes);
    user.value = apiUser;
  };

  const updatePassword = async (password: string) => {
    await resetPasswordCurrentUser(password);
  };

  const deleteUser = async () => {
    await deleteCurrentUser();
    clear();
  };

  return {
    user,
    roles,
    isSuperUser,
    organizationRoles,
    inboxRoles,
    isCurrentOrganizationAdmin,
    loadUser,
    clear,
    updateUser,
    updatePassword,
    deleteUser,
    isMemberOfMultipleOrganizations,
    frontendPreferences,
  };
});
