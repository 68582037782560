<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="members" />
    <q-input
      dense
      outlined
      clearable
      v-model="search"
      :label="$t('Search')"
      :disable="isLoading"
      class="q-mb-md"
      @clear="search = ''"
    />
    <q-skeleton v-if="isLoading" />
    <q-list v-else separator>
      <q-item v-for="member in filteredMembers" :key="member.id">
        <q-item-section avatar>
          <user-avatar :user="member" />
        </q-item-section>
        <q-item-section style="flex-grow: 20000">
          <q-item-label
            >{{ member.firstName }} {{ member.lastName }}</q-item-label
          >
          <q-item-label caption>{{ member.email }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-badge
            class="q-mr-sm cursor-pointer"
            color="white"
            text-color="grey-8"
            :label="
              member.role ? $t(RoleLabels[member?.role]) : $t('Not a member')
            "
          >
            <q-menu v-if="canWrite" anchor="bottom left" auto-close>
              <q-item
                v-for="role in inboxRoleOptions"
                :key="role"
                clickable
                v-ripple
                @click="updateRole(member, role)"
              >
                <q-item-section>
                  <q-item-label>{{ $t(RoleLabels[role]) }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-menu>
            <q-btn
              flat
              dense
              round
              v-if="canWrite && member.role !== null"
              icon="sym_r_close"
              @click.prevent.stop="updateRole(member, null)"
              size="sm"
            />
          </q-badge>
        </q-item-section>
        <q-item-section side style="width: 100px">
          <user-status-badge :user="member" />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/user";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import UserAvatar from "@/components/User/UserAvatar.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { UserWithRole } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { inboxRoleOptions, RoleLabels } from "@/types/role";
import type { InboxRoleName } from "@/types/role";
import UserStatusBadge from "@/components/User/UserStatusBadge.vue";

const { organizationId, inboxId } = useRouteParams();

const { hasCurrentInboxRole } = storeToRefs(useCurrentPermissionsStore());
const canWrite = computed(() => hasCurrentInboxRole.value["inbox_admin"]);

const members = ref<UserWithRole[]>([]);
const isLoading = ref(false);
const search = ref("");
const filteredMembers = computed(() =>
  members.value.filter(
    (member) =>
      member.firstName.toLowerCase().includes(search.value.toLowerCase()) ||
      member.lastName.toLowerCase().includes(search.value.toLowerCase()) ||
      member.email.toLowerCase().includes(search.value.toLowerCase())
  )
);

async function loadMembersData() {
  isLoading.value = true;
  try {
    members.value = await api.listInboxMembersAndNonMembers(
      organizationId.value,
      inboxId.value
    );
  } finally {
    isLoading.value = false;
  }
}

async function updateRole(member: UserWithRole, role: InboxRoleName | null) {
  await api.updateUserRole(organizationId.value, member, role, inboxId.value);
  member.role = role;
}

watch([organizationId, inboxId], loadMembersData, { immediate: true });
</script>
