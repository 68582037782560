import { uploadDocument as apiUploadDocument } from "@/api/document";
import FileUploadDialog from "@/components/FileUploadDialog.vue";
import { downloadAsJson } from "@/utils/download";
import { useQuasar } from "quasar";
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { nextTick } from "vue";

export function useLeadUpload(
  inboxId: Ref<number>,
  loadData: () => Promise<void>
) {
  const q = useQuasar();
  const { t } = useI18n();

  const allowedExtensions = [".pdf", ".msg", ".eml"];

  function showUploadDialog() {
    q.dialog({
      component: FileUploadDialog,
      componentProps: {
        loadData,
      },
    });
  }

  async function uploadDocuments(files: FileList) {
    let uploadedFiles = 0;
    const failedFiles: { filename: string; error: string }[] = [];

    const dialog = q.dialog({
      message: buildUploadMessage(
        uploadedFiles,
        failedFiles.length,
        files.length
      ),
      progress: true,
      persistent: true,
      ok: false,
    });

    for (const file of files) {
      if (
        !allowedExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
      ) {
        q.notify({
          message: t("Upload error. This file type is not supported."),
          type: "negative",
          textColor: "white",
          timeout: 0,
          actions: [
            {
              label: t("Close"),
              color: "white",
              handler: () => {},
              noDismiss: false,
            },
          ],
        });
        await nextTick();
        dialog.hide();
      }
    }

    for (const file of files) {
      try {
        await apiUploadDocument(inboxId.value, file, false);
      } catch (e: any) {
        failedFiles.push({ filename: file.name, error: e.message });
      }
      uploadedFiles++;

      dialog.update({
        message: buildUploadMessage(
          uploadedFiles,
          failedFiles.length,
          files.length
        ),
      });
    }

    dialog.update({ message: t("Loading data...") });
    await loadData();
    dialog.hide();

    if (failedFiles.length) {
      q.notify({
        message: t("Some files could not be uploaded"),
        type: "negative",
        textColor: "white",
        timeout: 0,
        actions: [
          {
            label: t("Download file list"),
            color: "white",
            handler: () => downloadAsJson(failedFiles, "failed_uploads.json"),
            noDismiss: true,
          },
          {
            label: t("Close"),
            color: "white",
            handler: () => {},
            noDismiss: false,
          },
        ],
      });
    }
  }

  function buildUploadMessage(
    uploadedFiles: number,
    failedFiles: number,
    totalFiles: number
  ) {
    let message = t("Uploading files...");
    message += ` ${uploadedFiles}/${totalFiles}`;
    if (failedFiles > 0) {
      message += ` (${failedFiles} ${t("failed")})`;
    }
    return message;
  }

  return { showUploadDialog, uploadDocuments };
}
