<template>
  <div class="slim-page">
    <settings-page-header section="account" page="notifications" />
    <div class="q-pa-md">
      <q-checkbox
        :label="$t('settings.account.notifications.email')"
        v-model="emailNotificationsEnabled"
        v-if="user"
        :disable="isUpdating"
      />
      <div v-else>
        <q-skeleton type="QInput" />
      </div>
      <div class="q-mt-md flex justify-end">
        <q-btn
          dense
          color="primary"
          :label="$t('Save')"
          @click="save"
          :disable="isUpdating || !user"
          :loading="isUpdating"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const userStore = useCurrentUserStore();
const { user } = storeToRefs(userStore);
const isUpdating = ref(false);

if (!user.value) {
  userStore.loadUser();
}

const emailNotificationsEnabled = ref(
  user.value?.emailNotificationsEnabled ?? false
);
watch(
  () => user.value,
  () => {
    emailNotificationsEnabled.value =
      user.value?.emailNotificationsEnabled ?? false;
  }
);

const q = useQuasar();
const { t } = useI18n();

async function save() {
  if (isUpdating.value || !user.value) {
    return;
  }

  isUpdating.value = true;
  try {
    await userStore.updateUser({
      emailNotificationsEnabled: emailNotificationsEnabled.value,
    });
    q.notify({
      type: "positive",
      message: t("settings.account.notifications.notificationsUpdated"),
    });
  } finally {
    isUpdating.value = false;
  }
}
</script>
