<template>
  <div class="changelog-widget-spacer"></div>
  <q-btn
    id="beamer-feedback-button"
    round
    size="sm"
    icon="sym_r_lightbulb"
    color="neutral-2"
    text-color="neutral-7"
    class="fab"
  >
    <q-tooltip anchor="top left" self="bottom left" :offset="[0, 10]">
      {{ $t("changelog.title") }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

onMounted(async () => {
  const beamerConfig = {
    // product id is already set in index.html
    language: getMainLocale(locale.value),
  };

  const beamer = await waitForBeamer();
  beamer.update(beamerConfig);
  beamer.init();
});

async function waitForBeamer() {
  const win: any = window;
  if (win.Beamer) {
    return win.Beamer;
  }
  await new Promise((resolve) => setTimeout(resolve, 100));
  return waitForBeamer();
}

function getMainLocale(locale: string) {
  return locale.split("-")[0].toUpperCase();
}
</script>

<style scoped lang="scss">
.changelog-widget-spacer {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
}
.fab {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}
</style>
