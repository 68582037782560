<template>
  <div class="column no-wrap page-below-header-bar">
    <pdf-display
      v-if="activeDocument && activeFile"
      ref="pdfDisplayEl"
      :file="activeFile"
      :document="activeDocument"
      :offer-position-groups="currentOfferPositionGroups"
      class="pdf-viewer"
    />
    <document-tabs
      v-if="boqs && activeDocumentId !== undefined && boqs.length > 0"
      :documents="boqs"
      v-model:active-document-id="activeDocumentId"
    />
  </div>
</template>

<script setup lang="ts">
import { getDocumentFile } from "@/api/document";
import DocumentTabs from "@/components/DocumentTabs/DocumentTabs.vue";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useRouteParams } from "@/composables/useRouteParams";
import { MAX_WAIT_FOR_PDF_DISPLAY_EL_MS } from "@/config/constants";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { waitFor } from "@/utils/waitFor";
import { storeToRefs } from "pinia";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowReactive,
  watch,
} from "vue";
import PdfDisplay from "../PdfDisplay/PdfDisplay.vue";

const { inboxId, inquiryId } = useRouteParams();

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { offerPositionGroups } = storeToRefs(
  useCurrentOfferPositionGroupsStore()
);

const documentFiles = shallowReactive<Map<number, File>>(new Map());

const boqs = computed(
  () => inquiry.value?.documents.filter((doc) => doc.isBoq) || []
);

const activeDocumentId = ref<number | undefined>(boqs.value[0]?.id);
watch(boqs, () => {
  activeDocumentId.value = boqs.value[0]?.id;
});

const activeDocument = computed(() => {
  return boqs.value.find((document) => document.id === activeDocumentId.value);
});

const activeFile = computed(() => {
  if (!activeDocumentId.value) return;
  return documentFiles.get(activeDocumentId.value);
});

const currentOfferPositionGroups = computed(() => {
  if (activeDocumentId.value === undefined) return [];
  return (
    offerPositionGroups.value?.filter(
      (group) => group.documentId === activeDocumentId.value
    ) || []
  );
});

watch(
  activeDocument,
  async (newActiveDocument) => {
    if (!newActiveDocument) return;
    if (documentFiles.has(newActiveDocument.id)) return;

    const file = await getDocumentFile(
      inboxId.value,
      inquiryId.value,
      newActiveDocument.id
    );

    if (!file) return;
    documentFiles.set(newActiveDocument.id, file);
  },
  { immediate: true }
);

const pdfDisplayEl = ref<typeof PdfDisplay | null>(null);

const events = usePositionsEvents();
onMounted(() => {
  events.on("tableRowClick", activateDocumentTab);
});
onBeforeUnmount(() => {
  events.off("tableRowClick", activateDocumentTab);
});

async function activateDocumentTab(groupId: number | null) {
  if (!groupId) return;
  const group = offerPositionGroups.value?.find(
    (group) => group.id === groupId
  );
  if (!group) return;
  if (group.documentId) activeDocumentId.value = group.documentId;
  await nextTick();
  await nextTick();
  const displayEl = await waitFor(
    () => pdfDisplayEl.value,
    MAX_WAIT_FOR_PDF_DISPLAY_EL_MS
  );
  if (!displayEl) {
    console.warn("Waiting for displayEl timed out");
    return;
  }
  displayEl.scrollToHighlight(groupId);
}
</script>

<style scoped lang="scss">
.pdf-viewer {
  height: 100%;
  flex-shrink: 1;
}
</style>
