<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="supplierTypeDetection" />
    <q-form @submit="saveKeywords" class="q-pa-md">
      <q-checkbox
        v-if="canWrite"
        dense
        v-model="showManufacturerTypes"
        :label="
          $t(
            'settings.organization.supplierTypeDetection.showManufacturerTypes'
          )
        "
      />
      <q-skeleton type="QCheckbox" v-else />
      <div class="q-mt-md">
        <q-input
          v-if="!isLoading"
          dense
          outlined
          v-model="ownKeywordsText"
          :readonly="!canWrite"
          :label="$t('settings.organization.supplierTypeDetection.ownKeywords')"
          type="textarea"
        />
        <q-skeleton type="QInput" style="height: 129px" v-else />
      </div>
      <div class="q-mt-md">
        <q-input
          v-if="!isLoading"
          dense
          outlined
          :readonly="!canWrite"
          v-model="externalKeywordsText"
          :label="
            $t('settings.organization.supplierTypeDetection.externalKeywords')
          "
          type="textarea"
        />
        <q-skeleton type="QInput" style="height: 129px" v-else />
      </div>
      <q-btn
        v-if="canWrite"
        dense
        class="q-mt-md float-right"
        type="submit"
        color="primary"
        :label="$t('settings.organization.supplierTypeDetection.save')"
        :disable="isLoading"
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/organization";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const ownKeywordsText = ref<string>("");
const externalKeywordsText = ref<string>("");
const showManufacturerTypes = ref<boolean>(false);
const isLoading = ref(true);

const { organizationId } = useRouteParams();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);

const { organization } = storeToRefs(useCurrentOrganizationStore());

const { updateOrganization, loadOrganization } = useCurrentOrganizationStore();

const canWrite = computed(
  () => hasCurrentOrganizationRole.value["product_admin"]
);

const q = useQuasar();
const { t } = useI18n();

async function loadKeywords() {
  isLoading.value = true;
  try {
    const keywords = await api.getKeywords(organizationId.value);
    ownKeywordsText.value = keywords.own.join("\n");
    externalKeywordsText.value = keywords.external.join("\n");
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadKeywords);
onMounted(loadShowManufacturerTypes);

async function loadShowManufacturerTypes() {
  await loadOrganization();
  showManufacturerTypes.value = organization.value!.showManufacturerTypes;
}

async function saveKeywords() {
  isLoading.value = true;
  try {
    if (
      organization.value &&
      showManufacturerTypes.value !== organization.value.showManufacturerTypes
    ) {
      organization.value.showManufacturerTypes = showManufacturerTypes.value;
      await updateOrganization(organization.value);
    }
    await api.setKeywords(organizationId.value, {
      own: ownKeywordsText.value
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x),
      external: externalKeywordsText.value
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x),
    });
    q.notify({
      message: t("settings.organization.supplierTypeDetection.notifySaved"),
      color: "positive",
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
