<template>
  <div class="flex row items-center no-wrap gap-x-md text-xs">
    <div class="flex row items-center gap-x-md">
      <span> {{ t("inboxPage.pagination.rowsPerPage") }}</span>
      <q-select
        :model-value="pageSize"
        @update:model-value="$emit('update:pageSize', $event)"
        :options="[10, 20, 50, 100]"
        dense
        borderless
      />
    </div>
    <div v-if="count">{{ $t("inboxPage.pagination.count", count) }}</div>
    <div>
      <q-btn
        flat
        dense
        icon="sym_r_chevron_left"
        @click="$emit('previousPage')"
        :disable="!hasPrevious"
      />
      <q-btn
        flat
        dense
        icon="sym_r_chevron_right"
        @click="$emit('nextPage')"
        :disable="!hasNext"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  pageSize: number | null;
  page: number | null;
  totalItems: number;
}>();

defineEmits<{
  "update:pageSize": [pageSize: number];
  nextPage: [];
  previousPage: [];
}>();

const { t } = useI18n();

const count = computed(() => {
  if (!props.pageSize || !props.page) return null;
  return {
    from: (props.page - 1) * props.pageSize + 1,
    to: Math.min(props.page * props.pageSize, props.totalItems),
    total: props.totalItems,
  };
});

const hasNext = computed(() => {
  if (!props.pageSize || !props.page) return false;
  return props.page * props.pageSize < props.totalItems;
});

const hasPrevious = computed(() => {
  if (!props.pageSize || !props.page) return false;
  return props.page > 1;
});
</script>
