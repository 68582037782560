<template>
  <tags-select
    v-if="inbox && inquiry"
    outlined
    :show-label="true"
    :available="inbox.tags"
    :selected="inquiry.tags"
    @add-tag="addTag"
    @remove-tag="removeTag"
  >
    <slot />
  </tags-select>
  <q-skeleton v-else type="rect" style="height: 40px" />
</template>

<script setup lang="ts">
import TagsSelect from "@/components/Tags/TagsSelect.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";

const { inbox } = storeToRefs(useCurrentInboxStore());
const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);
const { addTag, removeTag } = store;
</script>
